export default [
	{
		header: 'Setting SPT Tahunan'
	},
	{
		title: 'Product',
		icon: 'ShoppingBagIcon',
		permission: 'list product',
		children: [
			// {
			// 	title: 'Product Feature',
			// 	route: '#'
			// },
			{
				title: 'Product List',
				route: 'product-list',
				permission: 'list product',
			},
			// {
			// 	title: 'Feature Controller',
			// 	route: '#' 
			// },
			{
				title: 'Consultation Price List',
				route: 'consultation-price-list',
				permission: 'list product'
			},
			{
				title: 'Wording Product Menu',
				route: 'product-menu',
				permission: 'list product'
			},
			{
				title: 'Coupon List',
				route: 'coupon-list',
				permission: 'list product'
			}
		]
	},
	{
		title: 'Progressive',
		icon: 'InboxIcon',
		route: 'progressive',
		permission: 'list progressive'
	},
	{
		title: 'PTKP',
		icon: 'SlashIcon',
		route: 'ptkp',
		permission: 'list ptkp'
	},
	{
		title: 'Setting PPh23',
		icon: 'SettingsIcon',
		route: 'setting-pph23',
		permission: 'list pph-23'
	},
	{
		title: 'Jenis Pekerjaan',
		icon: 'LinkedinIcon',
		children: [
			{
				title: 'List Group',
				route: 'group-jenis-pekerjaan',
			},
			{
				title: 'List Jenis Pekerjaan',
				route: 'jenis-pekerjaan',
			}
		]
	},
	// {
	// 	title: 'Tarif Omset',
	// 	icon: 'BookOpenIcon',
	// 	route: 'tarif-omset'
	// },
	// {
	// 	title: 'Omset Setting',
	// 	icon: 'SettingsIcon',
	// 	route: 'omset-setting',
	// 	permission: 'list omzet setting'
	// },
	{
		title: 'Question Copywriting',
		icon: 'MessageCircleIcon',
		children: [
			// {
			// 	title: 'SPT',
			// 	route: 'spt',
			// 	permission: 'list SPT question'
			// },
			{
				title: 'Rekomendasi Pajak',
				route: 'compliting-profile',
				permission: 'list assessment question'
			},
		]
	},
	// {
	// 	title: 'Biaya PKB',
	// 	icon: 'ClipboardIcon',
	// 	children: [
	// 		// {
	// 		// 	title: 'Biaya Platform',
	// 		// 	route: 'biaya-platform',
	// 		// },
	// 		{
	// 			title: 'Biaya Admin',
	// 			route: 'biaya-admin',
	// 		},
	// 	]
	// },
]