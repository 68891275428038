export default [
	{
		header: 'Accounts',
	},
	{
		title: 'Customer',
		icon: 'UsersIcon',
		children: [
			{
				title: 'Customer List',
				route: 'customers',
				permission: 'list customer'
			},
			{
				title: 'Activity Log',
				route: 'activity-log',
			},
			{
				title: 'EFIN',
				route: 'efin',
				permission: 'list efin request'
			},
			{
				title: 'Rekomendasi Pajak',
				route: 'tax-recomendation',
				permission: 'list tax assessment',
			},
			{
				title: 'NPWP Request',
				route: 'npwp-request',
				permission: 'list npwp request',
			},
		]
	},
	{
		title: 'Transaction',
		icon: 'TagIcon',
		route: 'transaction',
	},
	// {
	// 	title: 'Tax Payment',
	// 	icon: 'DollarSignIcon',
	// 	route: '#'
	// }
]
