export default [
	{
		header: 'Administrator',
	},
	{
		title: 'Admin',
		icon: 'UserIcon',
		route: 'admin-user',
		permission: 'list admin',
	},
	// {
	// 	title: 'Log Activity',
	// 	icon: 'ActivityIcon',
	// 	route: '#',
	// },
]